import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  initNotificationRegistration,
  registrationSelectors,
} from '../../../redux/registration/registration.slice';
import { EMailForm } from '../../components/EMailForm';
import { deleteInitialEMail } from '../../../redux/application/application.slice';

const EMail = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(registrationSelectors.isLoading);
  const error = useAppSelector(registrationSelectors.error);
  const status = useAppSelector(registrationSelectors.status);

  return (
    <>
      <EMailForm
        error={status === undefined ? error : undefined}
        loading={isLoading}
        onSubmit={(email) => {
          dispatch(initNotificationRegistration({ process: { recipient: email, type: 'EMAIL' } }));
          dispatch(deleteInitialEMail());
        }}
        submitButtonText={'Registrieren'}
      />
    </>
  );
};

export default EMail;
