import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  IApplicationConfig,
  IGetApplicationErrorPayload,
  IGetApplicationSuccessPayload,
  ISetTokenSuccessPayload,
  IGetUserErrorPayload,
  IGetUserSuccessPayload,
  IUser,
  ISetTokenErrorPayload,
  ISetTokenPayload,
} from './application.types';
import { IError } from '../types';

interface IApplicationState {
  initialized: boolean;
  config?: IApplicationConfig;
  token?: string;
  initialEMail?: string;
  user?: IUser;
  error?: IError;
  loading: boolean;
  closableReferer?: boolean;
}

const initialApplicationState: IApplicationState = {
  initialized: false,
  config: undefined,
  token: undefined,
  initialEMail: undefined,
  user: undefined,
  error: undefined,
  loading: false,
  closableReferer: false,
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState: initialApplicationState,
  reducers: {
    initializeApplication: (state) => {
      state.initialized = true;
    },
    getApplicationSuccess: (state, action: PayloadAction<IGetApplicationSuccessPayload>) => {
      state.config = action.payload.config;
    },
    getApplicationError: (state, _action: PayloadAction<IGetApplicationErrorPayload>) => {
      state.config = undefined;
    },
    setToken: (state, _action: PayloadAction<ISetTokenPayload>) => {
      state.error = undefined;
      state.loading = true;
    },
    setTokenSuccess: (state, action: PayloadAction<ISetTokenSuccessPayload>) => {
      state.token = action.payload.token;
      state.loading = false;
    },
    setTokenError: (state, action: PayloadAction<ISetTokenErrorPayload>) => {
      state.token = undefined;
      state.error = action.payload.error;
    },
    setInitialEMail: (state, action: PayloadAction<string | null>) => {
      state.initialEMail = action.payload ?? undefined;
    },
    deleteInitialEMail: (state) => {
      state.initialEMail = undefined;
    },
    getUser: (state) => {
      state.error = undefined;
      state.loading = true;
    },
    getUserSuccess: (state, action: PayloadAction<IGetUserSuccessPayload>) => {
      state.user = action.payload.user;
      state.loading = false;
    },
    getUserError: (state, action: PayloadAction<IGetUserErrorPayload>) => {
      state.user = undefined;
      state.error = action.payload.error;
      state.loading = false;
    },
    logout: (state) => {
      state.initialized = false;
      state.token = undefined;
      state.user = undefined;
      state.error = undefined;
      state.loading = false;
    },
    setClosableReferer: (state, action: PayloadAction<boolean>) => {
      state.closableReferer = action.payload;
    },
  },
});

// ACTIONS
export const {
  initializeApplication,
  getApplicationSuccess,
  getApplicationError,
  setToken,
  setTokenSuccess,
  setTokenError,
  setInitialEMail,
  deleteInitialEMail,
  getUser,
  getUserSuccess,
  getUserError,
  logout,
  setClosableReferer,
} = applicationSlice.actions;

// SELECTORS
const getSubState = (state: RootState) => state.application as IApplicationState;
const initialized = createSelector(getSubState, (state) => state.initialized);

const config = createSelector(getSubState, (state) => state.config);
const tenant = createSelector(config, (state) => state?.tenant);
const tenantShort = createSelector(config, (state) => state?.tenantShort);

const communications = createSelector(config, (state) => state?.communications);

const privacy = createSelector(config, (state) => state?.privacy);
const imprint = createSelector(config, (state) => state?.imprint);
const nav = createSelector(config, (state) => state?.nav);

const token = createSelector(getSubState, (state) => state.token);
const initialEMail = createSelector(getSubState, (state) => state.initialEMail);
const user = createSelector(getSubState, (state) => state.user);

const error = createSelector(getSubState, (state) => state.error);

const isLoading = createSelector(getSubState, (state) => state.loading);

const isClosable = createSelector(getSubState, (state) => state.closableReferer);

export const applicationSelectors = {
  initialized,
  communications,
  tenant,
  tenantShort,
  privacy,
  imprint,
  nav,
  token,
  initialEMail,
  user,
  error,
  isLoading,
  isClosable
};

// REDUCER
export default applicationSlice.reducer;
