import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  IConfirmNotificationRegistrationErrorPayload,
  IConfirmNotificationRegistrationPayload,
  IConfirmNotificationRegistrationSuccessPayload,
  IInitNotificationRegistrationErrorPayload,
  IInitNotificationRegistrationPayload,
  IInitNotificationRegistrationSuccessPayload,
  INotificationRegistrationProcess,
} from './registration.types';
import { IError } from '../types';
import dayjs, { Dayjs } from 'dayjs';

interface IRegistrationState {
  process?: INotificationRegistrationProcess;
  error?: IError;
  created?: number;
  loading: boolean;
}

const initialRegistrationState: IRegistrationState = {
  process: undefined,
  error: undefined,
  loading: false,
};

export const registrationSlice = createSlice({
  name: 'registration',
  initialState: initialRegistrationState,
  reducers: {
    initNotificationRegistration: (
      state,
      action: PayloadAction<IInitNotificationRegistrationPayload>,
    ) => {
      state.process = action.payload.process;
      state.created = undefined;
      state.error = undefined;
      state.loading = true;
    },
    initNotificationRegistrationSuccess: (
      state,
      action: PayloadAction<IInitNotificationRegistrationSuccessPayload>,
    ) => {
      state.process = action.payload.process;
      state.created = action.payload.created;
      state.loading = false;
    },
    initNotificationRegistrationError: (
      state,
      action: PayloadAction<IInitNotificationRegistrationErrorPayload>,
    ) => {
      state.process = undefined;
      state.created = action.payload.created;
      state.error = action.payload.error;
      state.loading = false;
    },
    confirmNotificationRegistration: (
      state,
      _action: PayloadAction<IConfirmNotificationRegistrationPayload>,
    ) => {
      state.loading = true;
      state.error = undefined;
    },
    confirmNotificationRegistrationSuccess: (
      state,
      action: PayloadAction<IConfirmNotificationRegistrationSuccessPayload>,
    ) => {
      state.process = action.payload.process;
      state.loading = false;
    },
    confirmNotificationRegistrationError: (
      state,
      action: PayloadAction<IConfirmNotificationRegistrationErrorPayload>,
    ) => {
      state.error = action.payload.error;
      state.loading = false;
    },
    clearError: (state) => {
      state.error = undefined;
      state.loading = false;
    },
  },
});

// ACTIONS
export const {
  initNotificationRegistration,
  initNotificationRegistrationSuccess,
  initNotificationRegistrationError,
  confirmNotificationRegistration,
  confirmNotificationRegistrationSuccess,
  confirmNotificationRegistrationError,
  clearError,
} = registrationSlice.actions;

// SELECTORS
const getSubState = (state: RootState) => state.registration as IRegistrationState;
const process = createSelector(getSubState, (state) => state.process);
const status = createSelector(process, (state) => state?.status);
const countdownRemaining = (now: Dayjs) =>
  createSelector(getSubState, (state) => {
    if (state?.created) {
      const diff = dayjs(now).diff(state?.created, 'seconds');

      return diff < 30 ? 30 - diff : 0;
    }

    return 0;
  });

const isLoading = createSelector(getSubState, (state) => state.loading);

const error = createSelector(getSubState, (state) => state.error);

export const registrationSelectors = {
  process,
  status,
  countdownRemaining,
  error,
  isLoading,
};

// REDUCER
export default registrationSlice.reducer;
