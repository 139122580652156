type ErrorContextType =
  | 'REGISTRATION_EMAIL'
  | 'REGISTRATION_CONFIRMATION'
  | 'LOGIN'
  | 'UNSUBSCRIBE'
  | 'UNSUBSCRIBE_TOPIC';

export const DEFAULT_ERROR_TEXT = 'Ups, etwas ist schiefgelaufen.';

export const resolveErrorText = (statusCode?: number, context?: ErrorContextType) => {
  let errorText;

  switch (context) {
    case 'LOGIN': {
      switch (statusCode) {
        case 403: {
          errorText =
            'Ihre Anmeldedaten sind nicht korrekt. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.';
          break;
        }
        default: {
          errorText = DEFAULT_ERROR_TEXT;
          break;
        }
      }
      break;
    }
    case 'REGISTRATION_EMAIL': {
      switch (statusCode) {
        case 400: {
          errorText =
            'Die eingegebene E-Mail-Adresse ist ungültig. Achten Sie bei erneuter Eingabe auf die Richtigkeit der E-Mail-Adresse.';
          break;
        }
        case 403: {
          errorText =
            'Eine E-Mail-Adresse wird gerade registriert und muss durch einen Code bestätigt werden. Falls Sie keinen Code erhalten haben, versuchen Sie die Registrierung bitte erneut.';
          break;
        }
        case 409: {
          errorText =
            'Diese E-Mail-Adresse ist bereits registriert. Sie können aber eine andere E-Mail-Adresse zusätzlich registrieren.';
          break;
        }
        default: {
          errorText = DEFAULT_ERROR_TEXT;
          break;
        }
      }
      break;
    }
    case 'REGISTRATION_CONFIRMATION': {
      switch (statusCode) {
        case 403: {
          errorText =
            'Der Code wurde zu oft falsch eingegeben und ist nun abgelaufen. Sie können einen neuen Code erhalten, indem Sie auf "Code erneut senden" klicken.';
          break;
        }
        case 400:
        case 404: {
          errorText =
            'Der eingegebene Code ist ungültig. Achten Sie bei erneuter Eingabe auf die Richtigkeit des Codes. Bei erneutem Auftreten des Fehlers klicken Sie bitte auf "Code erneut senden" und geben Sie den neuen Code ein.';
          break;
        }
        case 410: {
          errorText =
            'Der eingegebene Code ist abgelaufen. Sie können einen neuen Code erhalten, indem Sie auf "Code erneut senden" klicken.';
          break;
        }
        default: {
          errorText = DEFAULT_ERROR_TEXT;
          break;
        }
      }
      break;
    }
    case 'UNSUBSCRIBE': {
      switch (statusCode) {
        default: {
          errorText = DEFAULT_ERROR_TEXT;
          break;
        }
      }
      break;
    }
    case 'UNSUBSCRIBE_TOPIC': {
      switch (statusCode) {
        default: {
          errorText = DEFAULT_ERROR_TEXT;
          break;
        }
      }
      break;
    }
    default: {
      errorText = DEFAULT_ERROR_TEXT;
      break;
    }
  }

  return errorText;
};
